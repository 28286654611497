/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React from 'react';

import image1 from 'static/genieworksfamily/landing_01.png';
import image2 from 'static/genieworksfamily/landing_02.png';
import image3 from 'static/genieworksfamily/landing_03.png';
import image4 from 'static/genieworksfamily/landing_04.png';
import image5 from 'static/genieworksfamily/landing_05.png';
import image6 from 'static/genieworksfamily/landing_06.png';

const Section1 = () => {
  const { mq } = useTheme();

  const Section1Style = css`
    display: flex;
    flex-direction: column;
    .wrap {
      width: 640px;
      margin: 0 auto;
      div {
        display: flex;
        a {
          display: flex;
        }
      }
      img {
        width: 100%;
      }
      .calc2 {
        width: 100%;
        img {
          flex: 1;
        }
      }
    }
    ${mq.mobile} {
      flex-direction: column;
      .wrap {
        width: 100%;
      }
    }
  `;

  return (
    <div css={Section1Style}>
      <div className="wrap">
        <div>
          <img src={image1} alt="image1" />
        </div>
        <div>
          <a href="https://vo.la/DWxBdM" target="_blank" rel="noreferrer">
            <img src={image2} alt="company" />
          </a>
        </div>
        <div>
          <img src={image3} alt="company" />
        </div>
        <div className="calc2">
          <div>
            <a href="https://vo.la/RRZHIl" target="_blank" rel="noreferrer">
              <img src={image4} alt="company" />
            </a>
          </div>
          <div>
            <a href="https://vo.la/rguNni" target="_blank" rel="noreferrer">
              <img src={image5} alt="company" />
            </a>
          </div>
        </div>
        <div>
          <img src={image6} alt="company" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
