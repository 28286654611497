import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CookiesProvider } from 'react-cookie';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css/navigation';
import 'styles/reset.css';

import Alert from 'components/common/Alert';
import Confirm from 'components/common/Confirm';

import GlobalStyle from './styles/global';
import theme from './styles/theme';
import Company from 'pages/company';
import NotFound from '404';
import Welfare from 'pages/welfare';
import Download from 'pages/download';
import Inquiry from 'pages/inquiry';
import GenieworksFamily from 'pages/genieworksfamily';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Welfare />,
  },
  {
    path: '/intro/company',
    element: <Company />,
  },
  {
    path: '/genieworksfamily',
    element: <GenieworksFamily />,
  },
  {
    path: '/download',
    element: <Download />,
  },
  {
    path: '/welfare',
    element: <Welfare />,
  },
  {
    path: '/inquiry',
    element: <Inquiry />,
  },
  { path: '/*', element: <NotFound /> },
]);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
      cacheTime: 1000 * 10,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
});

const App = () => {
  return (
    <>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {/* <UserProvider userInfo=""> */}
            <RouterProvider router={router} />
            {/* </UserProvider> */}
            <Alert />
            <Confirm />
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CookiesProvider>
    </>
  );
};

export default App;
